html,
body,
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}

.makeStyles-appBarShift-1 .page-header-pinned {
  width: calc(100% - 235px) !important;
}
.ant-popover-inner-content {
  padding: 12px 0px 0px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  background: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

span.ant-radio + * {
  padding-right: 8px;
  width: 100%;
  padding-left: 8px;
}

h1,
h2,
h3,
h4 {
  margin: 0 !important;
}

.fundo-escuro {
  background-color: rgba(0, 0, 0, 0.05);
}

.relatorio-table td {
  border-right: 1px solid #cecece !important;
  border-bottom: 1px solid #cecece !important;
}
