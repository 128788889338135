@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?57178058');
  src: url('../font/fontello.eot?57178058#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?57178058') format('woff2'),
       url('../font/fontello.woff?57178058') format('woff'),
       url('../font/fontello.ttf?57178058') format('truetype'),
       url('../font/fontello.svg?57178058#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?57178058#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-rolos-ut:before { content: '\e800'; } /* '' */
.icon-operacoes:before { content: '\e801'; } /* '' */
.icon-ordens-de-colheita:before { content: '\e802'; } /* '' */
.icon-algodoeira:before { content: '\e803'; } /* '' */
.icon-armazenamento:before { content: '\e804'; } /* '' */
.icon-engines:before { content: '\e805'; } /* '' */
.icon-equipe:before { content: '\e806'; } /* '' */
.icon-parametros:before { content: '\e807'; } /* '' */
.icon-talhoes:before { content: '\e808'; } /* '' */
.icon-variedades:before { content: '\e809'; } /* '' */
.icon-patio:before { content: '\e80a'; } /* '' */
.icon-comparar-maquinas-4:before { content: '\e80b'; } /* '' */
.icon-cancel:before { content: '\e80c'; } /* '' */
.icon-cancel-1:before { content: '\e80d'; } /* '' */
.icon-fact_check:before { content: '\e80e'; } /* '' */
.icon-sessoes:before { content: '\e80f'; } /* '' */
.icon-falha-no-estorno:before { content: '\e810'; } /* '' */
.icon-fardinho:before { content: '\e811'; } /* '' */
.icon-rolo-beneficiado:before { content: '\e812'; } /* '' */
.icon-maquina:before { content: '\e813'; } /* '' */
.icon-fazenda-origem:before { content: '\e814'; } /* '' */
.icon-paradas:before { content: '\e815'; } /* '' */
.icon-pendencia-no-estorno:before { content: '\e816'; } /* '' */
.icon-rendimento:before { content: '\e817'; } /* '' */
.icon-rolo:before { content: '\e818'; } /* '' */
.icon-rolo-na-fila-de-sincronização:before { content: '\e819'; } /* '' */
.icon-rolos-com-pendencia:before { content: '\e81a'; } /* '' */
.icon-rolos-estornos:before { content: '\e81b'; } /* '' */
.icon-rolos-nao-sincronizados:before { content: '\e81c'; } /* '' */
.icon-rolos-processando-estorno:before { content: '\e81d'; } /* '' */
.icon-rolos-sem-peso:before { content: '\e81e'; } /* '' */
.icon-rolos-sincronizados:before { content: '\e81f'; } /* '' */
.icon-sem-fardinhos:before { content: '\e820'; } /* '' */
.icon-sem-rolo:before { content: '\e821'; } /* '' */
.icon-turno-diurno:before { content: '\e822'; } /* '' */
.icon-turno-noturno:before { content: '\e823'; } /* '' */
.icon-ver-detalhes:before { content: '\e824'; } /* '' */
.icon-rolo-talhao:before { content: '\e825'; } /* '' */
.icon-talhao:before { content: '\e826'; } /* '' */
.icon-fazenda-selecao:before { content: '\e827'; } /* '' */
.icon-motivos-de-parada:before { content: '\e828'; } /* '' */
.icon-rolo-rendimento:before { content: '\e829'; } /* '' */
.icon-comercial:before { content: '\e82a'; } /* '' */
.icon-comparar-maquinas-1:before { content: '\e82b'; } /* '' */
.icon-comparar:before { content: '\e82c'; } /* '' */
.icon-comparar-maquinas-3:before { content: '\e82d'; } /* '' */
.icon-qrcode:before { content: '\e82e'; } /* '' */
.icon-chart:before { content: '\e82f'; } /* '' */
.icon-rolo-variedade:before { content: '\e830'; } /* '' */
.icon-conferencia-mapa:before { content: '\e831'; } /* '' */
.icon-data-mapa:before { content: '\e832'; } /* '' */
.icon-rolo-rendimento-1:before { content: '\e833'; } /* '' */
.icon-comparar-maquinas-2:before { content: '\e834'; } /* '' */
.icon-link-out:before { content: '\e835'; } /* '' */
.icon-remover-acts:before { content: '\e836'; } /* '' */
.icon-xlsx:before { content: '\e837'; } /* '' */
.icon-lote:before { content: '\e838'; } /* '' */
.icon-takeup-aprovado-1:before { content: '\e839'; } /* '' */
.icon-takeup-reprovado-1:before { content: '\e83a'; } /* '' */
.icon-takeup-standby-1:before { content: '\e83b'; } /* '' */
.icon-takeup-nao-avaliado-1:before { content: '\e83c'; } /* '' */
.icon-contratos-1:before { content: '\e83d'; } /* '' */
.icon-hvi-1:before { content: '\e83e'; } /* '' */
.icon-comercial-future-1:before { content: '\e83f'; } /* '' */
.icon-comercial-reprove-1:before { content: '\e840'; } /* '' */
.icon-comercial-date-1:before { content: '\e841'; } /* '' */
.icon-comercial-scheduled-1:before { content: '\e842'; } /* '' */
.icon-comercial-check-1:before { content: '\e843'; } /* '' */
.icon-relatorios-hvi-1:before { content: '\e844'; } /* '' */
.icon-keyboard:before { content: '\e845'; } /* '' */
.icon-takeup-aprovado:before { content: '\e846'; } /* '' */
.icon-takeup-reprovado:before { content: '\e847'; } /* '' */
.icon-takeup-standby:before { content: '\e848'; } /* '' */
.icon-takeup-nao-avaliado:before { content: '\e849'; } /* '' */
.icon-contratos:before { content: '\e84a'; } /* '' */
.icon-hvi:before { content: '\e84b'; } /* '' */
.icon-comercial-future:before { content: '\e84c'; } /* '' */
.icon-comercial-reprove:before { content: '\e84d'; } /* '' */
.icon-comercial-date:before { content: '\e84e'; } /* '' */
.icon-comercial-scheduled:before { content: '\e84f'; } /* '' */
.icon-comercial-check:before { content: '\e850'; } /* '' */
.icon-icone-classificador:before { content: '\e851'; } /* '' */
.icon-icone-classificacao:before { content: '\e852'; } /* '' */
.icon-icone-email:before { content: '\e853'; } /* '' */
.icon-icone-takeup-edicao:before { content: '\e854'; } /* '' */
.icon-icone-matutino-1:before { content: '\e855'; } /* '' */
.icon-icone-verspertino-1:before { content: '\e856'; } /* '' */
.icon-icone-matutino-micro:before { content: '\e857'; } /* '' */
.icon-icone-o-dia-todo-1:before { content: '\e858'; } /* '' */
.icon-relatorios-hvi:before { content: '\e859'; } /* '' */
.icon-icone-takeup-realizado:before { content: '\e85a'; } /* '' */
.icon-icone-takeup-agendado:before { content: '\e85b'; } /* '' */
.icon-icone-takeup-andamento:before { content: '\e85c'; } /* '' */
.icon-icone-periodo:before { content: '\e85d'; } /* '' */
.icon-icone-verspertino:before { content: '\e85e'; } /* '' */
.icon-icone-matutino:before { content: '\e85f'; } /* '' */
.icon-icone-o-dia-todo:before { content: '\e860'; } /* '' */
.icon-icone-motorista-1:before { content: '\e861'; } /* '' */
.icon-email-beneficiamento-diario:before { content: '\e862'; } /* '' */
.icon-barco-1:before { content: '\e863'; } /* '' */
.icon-icone-motorista:before { content: '\e864'; } /* '' */
.icon-caminhao-cif:before { content: '\e865'; } /* '' */
.icon-caminhao-fob:before { content: '\e866'; } /* '' */
.icon-cliente:before { content: '\e867'; } /* '' */
.icon-barco:before { content: '\e868'; } /* '' */
.icon-sem-acts-branco:before { content: '\e869'; } /* '' */
.icon-com-acts-branco:before { content: '\e86a'; } /* '' */
.icon-confirm-button:before { content: '\e86c'; } /* '' */
.icon-peso-entrada:before { content: '\e86d'; } /* '' */
.icon-peso-saida:before { content: '\e86e'; } /* '' */
.icon-carregamento-realizado:before { content: '\e872'; } /* '' */
.icon-carregando:before { content: '\e875'; } /* '' */
.icon-balanca-em-leitura:before { content: '\e878'; } /* '' */
.icon-balanca-sem-leitura:before { content: '\e879'; } /* '' */
.icon-icone-cargas:before { content: '\e889'; } /* '' */
.icon-icone-carregamentos-1:before { content: '\e88d'; } /* '' */
.icon-icone-cargas-2:before { content: '\e88e'; } /* '' */
.icon-icone-carregamento:before { content: '\e89e'; } /* '' */
.icon-fardinho-no-porto:before { content: '\e8ab'; } /* '' */
.icon-faturado:before { content: '\e8ac'; } /* '' */
